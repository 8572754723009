<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.grupovoz.com.br"
        target="_blank"
      >GRUPO VOZ</b-link>
      <span class="d-none d-sm-inline-block">, todos os direitos reservados</span>
    </span>
    <span
      class="float-md-right d-none d-md-block cursor-pointer"
      @click="openModalVersao"
    >{{ versao.length >0 ? versao[0].numero_versao : '' }}
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
    <b-modal
      ref="modalVersao"
      hide-footer
      title="Informações sobre a última versão"
    >
      <app-collapse
        accordion
        type="margin"
        class="mt-2"
      >

        <app-collapse-item
          :title="versao.length >0 ? versao[0].numero_versao : '' "
        >
          <div v-html="versao.length >0 ? versao[0].descricao : '' " />
        </app-collapse-item>

      </app-collapse>
    </b-modal>
  </p>
</template>

<script>
import { BLink, BModal } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BLink,
    BModal,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      versao: [],
    }
  },
  created() {
    this.getVersao()
  },
  methods: {
    async getVersao() {
      axios.get('api/v1/versao', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(item => {
          this.versao.unshift({
            numero_versao: item.numero_versao,
            descricao: item.descricao,
          })
        })
      })
    },
    openModalVersao() {
      this.$refs.modalVersao.show()
    },
  },
}
</script>
