<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        v-if="checkAcessoCredor"
        :badge="moedaBR(faturamento)"
        badge-classes="bg-primary"
        class="text-body"
        icon="DollarSignIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Faturamento mensal
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ moedaBR(faturamento) }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="ultimosRecebimentos"
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->

      <!-- System Notification Toggler -->
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          Últimos rececimentos
        </h6>
      </div>

      <!-- System Notifications -->
      <b-link
        v-for="r in ultimosRecebimentos"
        :key="r.total_faturamento"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              variant="light-success"
            >
              <feather-icon icon="CheckIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ r.instituicao.slice(0,26) }}
            </span>
            <span class="font-weight-bolder text-success">
              + {{ moedaBR(r.total_faturamento) }}
            </span>
          </p>
          <small class="notification-text"> {{ r.cliente.slice(0,40) }}<br>{{ formatarDataHora(r.data_baixa) }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Ver todo faturamento</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BLink, BAvatar, BButton, BMedia,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import axios from '@/../axios-auth'
import firebase from '@/../firebase'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BMedia,
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      ultimosRecebimentos: null,
      faturamento: null,
    }
  },
  computed: {
    checkAcessoCredor() {
      const { credores } = localStorage
      if (credores) {
        return false
      }
      return true
    },
  },
  created() {
    // ultimos recebimentos
    this.getUltimosRecebimentos()
    // totalFaturamento
    this.getTotalFaturamento()
  },
  mounted() {
    firebase.ref('notificacao/novaBaixa').on('value', snapshot => {
      const novoAcordo = snapshot.val()
      if (novoAcordo) {
        this.getUltimosRecebimentos()
        this.getTotalFaturamento()
        firebase.ref('notificacao/novaBaixa').set(false)
      }
    })
  },
  methods: {
    formatarDataHora(dataHoraOriginal) {
      const partes = dataHoraOriginal.split(' ')
      const dataPartes = partes[0].split('-')
      const horaPartes = partes[1].split(':')

      const dia = dataPartes[2]
      const mes = dataPartes[1]
      const hora = horaPartes[0]
      const minuto = horaPartes[1]

      return `${dia}/${mes} às ${hora}:${minuto}`
    },
    moedaBR(valor) {
      const numero = parseFloat(valor)
      const moeda = numero.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
      return moeda
    },
    getUltimosRecebimentos() {
      axios.get('api/v1/dashboard/ultimos-recebimentos', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.ultimosRecebimentos = res.data.dados
      })
    },
    getTotalFaturamento() {
      axios.get('api/v1/dashboard/faturamento/mensal', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.faturamento = res.data.dados[0].total_faturamento
      })
    },
  },
}
</script>

<style>

</style>
